
import { defineComponent, computed, watch } from '@nuxtjs/composition-api';
import { useInterval } from '@vueuse/core';

export default defineComponent({
  props: {
    duration: {
      type: Number,
      default: 3000,
    },
    isLoaded: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const interval = props.duration / 100;
    const { counter, pause } = useInterval(interval, {
      controls: true,
    });

    const currentCount = computed(() => {
      if (!props.isLoaded) {
        return counter.value;
      }

      return 100;
    });

    watch(counter, (newValue) => {
      if (newValue === 100) {
        pause();
      }
    });

    return {
      currentCount,
    };
  },
});
