
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    counterDuration: {
      type: Number,
      default: 1000,
    },
    isLoaded: {
      type: Boolean,
      default: false,
    },
  },
});
